.navbar {
  transition: background-color 300ms ease;
  position: relative;
  width: 100%;
  z-index: 1000;
}

.navbar-logo-card {
  float: left;
  background-color: transparent;
  width: 100px;
  height: 100px;
}

.navbar-logo-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.navbar-logo-card:hover .navbar-logo-card-inner {
  transform: rotateY(360deg);
}

.navbar-logo {
  border-radius: 20px;
  height: 80px;
  width: auto;
  vertical-align: middle;
  margin: 10px 0px 0px 15px;
  transition: all 2s;
  display: inline-block;
  background: #999;
  position: relative;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.29);
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.nav-links {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-item.dropdown .navButton:hover .chevron-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.chevron-icon {
  transition: transform 0.3s ease;
}

.nav-item {
  font-family: Inter, system-ui, sans-serif;
  font-size: 100%;
  position: relative;
  margin-right: 20px;
}

.nav-link {
  display: block;
  padding: 15px 20px;
  color: #ffffff;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: rgb(165, 182, 249);
  transition: width 0.3s ease-in-out;
}

.nav-link:hover::after {
  width: 100%;
}

.nav-spacer {
  flex: 1;
}

.dropdown-menu {
  max-width: 800px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  z-index: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

.dropdown-item:nth-child(1) {
  animation-delay: 0.1s;
}

.dropdown-item:nth-child(2) {
  animation-delay: 0.2s;
}

.dropdown-item:nth-child(3) {
  animation-delay: 0.3s;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-link {
  display: block;
  padding: 12px 20px;
  color: #ffffff;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.dropdown-link:hover {
  background-color: #1abc9c;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bridge {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 24px);
  height: calc(100% + 24px);
}


#overlay-content {
  position: fixed;
  right: 0%;
  width: 40%;
  border-radius: 0.5rem;
  border: 1px solid #4b5563;
  background: linear-gradient(180deg, #111827, #111827 50%, #1f2937 100%);
  padding: 1rem;
  z-index: 1000;
}

.navButton {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  background-color: transparent;
}

.content {
  position: absolute;
  left: -50%;
  top: calc(100% + 24px);
  width: 24rem;
  border-radius: 0.5rem;
  border-color: rgb(75, 85, 99);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: rgb(17, 24, 39);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17, 24, 39), var(--tw-gradient-to, rgba(17, 24, 39, 0));
  --tw-gradient-to: #1f2937;
  padding: 1rem;
}

.dropdown-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.misc-container {
  display: flex;
  gap: 1rem;
}

.misc-section {
  margin-bottom: 0.5rem;
}
.misc-container, .apis-container {
  flex: 1;
  margin: 0 10px; /* Adjust margin as needed for spacing */
}

.misc-section-title, .apis-section-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.misc-link, .apis-link {
  display: block;
  color: #9ca3af;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.misc-link:hover, .apis-link:hover {
  color: #ffffff;
}

.view-more-btn {
  margin-left: auto;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  color: #a5b4fc;
  text-decoration: none;
}

.view-more-btn:hover {
  color: #ffffff;
}

.nub {
  position: absolute;
  top: 0;
  height: 1rem;
  width: 1rem;
  transform: translateX(700%) translateY(-50%) rotate(45deg);
  border-top-left-radius: 0.25rem;
  border: 1px solid #4b5563;
  background-color: #111827;
}


.pricing-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  border-right: 1px solid #374151;
}

.pricing-link {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  color: #9ca3af;
  text-decoration: none;
  transition: color 0.3s ease;
}

.pricing-link:hover {
  color: #ffffff;
}

.pricing-icon {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  color: #a5b4fc;
}

.pricing-text {
  font-size: 0.75rem;
}


.blog-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.blog-link {
  text-decoration: none;
}

.blog-image {
  margin-bottom: 0.5rem;
  height: 3.5rem;
  width: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
}

.blog-title {
  margin-bottom: 0.125rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #ffffff;
}

.blog-description {
  font-size: 0.75rem;
  color: #9ca3af;
}

.table {
  border-radius: 15px;
  background-color: rgb(39, 62, 71, .6);
}

td {
  vertical-align: middle;
  padding: 0px 20px;
}

#linkedin {
  text-align: center;
}

.mobile-menu {
  display: none;
  flex-direction: column;
  background-color: #333;
  position: absolute;
  top: 60px;
  right: 20px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mobile-menu a {
  color: #ffffff;
  padding: 10px;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.mobile-menu a:hover {
  background-color: #1abc9c;
}

.mobile-menu-icon {
  font-size: 24px;
  color: #ffffff;
  background: none;
  border: none;
  cursor: pointer;
  display: none;
}

@media (max-width: 768px) {
  .mobile-menu-icon {
    display: block;
  }

  .nav-links {
    display: none;
  }

  .mobile-menu {
    display: flex;
  }
}