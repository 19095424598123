.terminal {
  background-color: #343434;
  color: #c5c8c6;
  font-family: 'Courier New', Courier, monospace;
  padding: 2px;
  border-radius: 5px;
  width: 750px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.terminal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px 5px 0 0;
  margin-bottom: 3px;
}

.terminal-header div {
  height: 12px;
  width: 12px;
  background-color: #d9534f;
  border-radius: 50%;
  margin-right: 5px;
}

.terminal-header span {
  text-align: center;
  width: 100%;
  position: absolute;
  font-weight: bold;
  font-size: 12px;
}

.terminal-header div:nth-child(2) {
  background-color: #f0ad4e;
}

.terminal-header div:nth-child(3) {
  background-color: #5cb85c;
}

.terminal-body {
  background-color: #132525;
  height: 400px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .terminal {
    width: 90%;
    padding: .8em;
    font-size: .7em;
    height: 50%;
  }

  .terminal-header {
    padding: 5px;
  }

  .terminal-header span {
    text-align: center;
    width: 100%;
    position: absolute;
    font-weight: bold;
    font-size: .75em;
  }
}

.terminal-cursor {
  animation: blink 0.7s steps(2, start) infinite;
}