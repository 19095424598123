.text-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: rgba(18, 25, 43, 0.834);
  border-radius: 15px;
}

.resume-download-container {
  text-align: center;
}

.resume-split-button {
  display: inline-flex;
  position: relative;
}

.resume-download-button {
  font-family: Arial, Helvetica, sans-serif;
  width: 175px;
  font-size: 16px;
  background-color: #0056b3;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px 0 0 5px;
  text-decoration: none;
  cursor: pointer;
}

.resume-dropdown {
  position: relative;
}

.resume-dropdown-toggle {
  font-size: 16px;
  width: 36px;
  font-weight: bold;
  height: auto;
  background-color: #0056b3;
  color: #ffffff;
  padding: 10px 10px;
  border: none;
  border-radius: 0 5px 5px 0;
  border-left: 1px solid #111625;
  cursor: pointer;
}

.resume-dropdown-toggle:hover,
.resume-download-button:hover {
  background-color: #004494;
}

.resume-dropdown-menu {
  width: 209px;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 1px;
  background-color: #0056b3;
  border: 1px solid #488bd4;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.resume-split-button:hover .resume-dropdown-menu {
  -webkit-animation: resume-dropdown-menu 0.5s linear both;
  animation: resume-dropdown-menu 0.5s linear both;
  display: block;
}

.resume-dropdown-menu a {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.resume-dropdown-menu a:hover {
  display: block;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background-color: #123b67;
}

@-webkit-keyframes resume-dropdown-menu {
  0% {
    -webkit-transform: scale(1) rotateX(0);
    transform: scale(1) rotateX(0);
  }

  50% {
    -webkit-transform: scale(1.1) rotateX(-180deg);
    transform: scale(1.1) rotateX(-180deg);
  }

  100% {
    -webkit-transform: scale(1) rotateX(0);
    transform: scale(1) rotateX(0);
  }
}

@keyframes resume-dropdown-menu {
  0% {
    -webkit-transform: scale(1) rotateX(0);
    transform: scale(1) rotateX(0);
  }

  50% {
    -webkit-transform: scale(1.1) rotateX(-180deg);
    transform: scale(1.1) rotateX(-180deg);
  }

  100% {
    -webkit-transform: scale(1) rotateX(0);
    transform: scale(1) rotateX(0);
  }
}