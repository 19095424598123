footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  background-color: rgba(24, 30, 38, .9);
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
  white-space: nowrap;
}

.footer-right a {
  display: inline-block;
  color: white;
  margin-left: 10px;
  text-decoration: none;
  transition: 0.9s ease 0s;
}

.footer-right a:hover {
  font-weight: bold;
  color: aqua;
  filter: drop-shadow(0px 0px 30px #ffffff);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}

footer p {
  color: rgb(184, 184, 184);
  font-size: 0.75rem;
  margin: 0;
}