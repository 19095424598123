.Phillies-container {
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0px 0px 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(17, 24, 39, .7);
}

.winLose {
  position: absolute;
  font-size: 2vw;
  bottom: 25%;
  /* z-index: 3; */
  color: #fff;
}

.at {
  position: absolute;
  top: 35%;
  text-align: center;
  font-size: 4.5vw;
  /* z-index: 4; */
  color: #fff;
}

.logo {
  position: absolute;
  top: 30%;
  max-Width: 10%;
}

.venue {
  position: absolute;
  top: 10%;
  left: 50%;
  font-size: 2vw;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.venue_img {
  position: relative;
  width: 100%;
  max-width: 800px;
}

/* loading */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #ff0000;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.spinner-logo {
  position: absolute;
  width: 50px;
  height: 50px;
  animation: spin-logo 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-logo {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}