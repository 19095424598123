@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&display=swap');

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  padding-bottom: 50px;
  box-sizing: border-box;
}

h1 {
  font-family: Lato, "Lucida Grande", "Lucida Sans Unicode", Tahoma, sans-serif;
  font-weight: 500;
  font-size: 2.5em;
  text-align: center;
  color: #ffffff;
  z-index: 1;
  margin: auto;
}

p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #ffffff;
  z-index: 1;
}

label {
  font-size: 1.5em;
  font-family: "Roboto Slab", serif;
  color: #ffffff;
  z-index: 1;
}

ul li {
  font-size: 1.5em;
  color: #ffffff;
}

/* img {
  transition: transform .5s;
  width: 50px;
  height: 50px;
  margin: 0 auto;
} */

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    text-shadow: 0 0 10px #ff6347, 0 0 20px #ff6347, 0 0 30px #ff6347;
  }

  50% {
    text-shadow: 0 0 20px #ff6347, 0 0 30px #ff6347, 0 0 40px #ff6347;
  }

  100% {
    text-shadow: 0 0 10px #ff6347, 0 0 20px #ff6347, 0 0 30px #ff6347;
  }
}

@keyframes glitch {
  0% {
    text-shadow: 1px 1px #ff00c1, -1px -1px #00fff9;
    transform: translate(0);
  }

  20% {
    text-shadow: 1px -1px #ff00c1, -1px 1px #00fff9;
    transform: translate(-2px, 2px);
  }

  40% {
    text-shadow: -1px 1px #ff00c1, 1px -1px #00fff9;
    transform: translate(2px, -2px);
  }

  60% {
    text-shadow: -1px -1px #ff00c1, 1px 1px #00fff9;
    transform: translate(0);
  }

  80% {
    text-shadow: 1px 1px #ff00c1, -1px -1px #00fff9;
    transform: translate(-2px, 2px);
  }

  100% {
    text-shadow: -1px -1px #ff00c1, 1px 1px #00fff9;
    transform: translate(2px, -2px);
  }
}

.fun {
  display: inline-block;
}

.navButton:hover .fun {

  animation: glitch 0.5s infinite;
  color: yellow;
  transform: rotate(360deg) scale(4);
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');
.cursor {
  font-family: "Special Elite", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  animation: blink 0.7s steps(2, start) infinite;
  border-right: 1px solid;
  margin-left: 2px;
}

.td p {
  padding: auto;
  font-family: "Raleway";
  font-weight: bold;
  font-size: 30px;
  color: #ffffff;
}

.vertical-timeline-element-date {
  font-family: "Raleway";
  margin-top: .2em;
  display: block;
  width: 100%;
  color: #ffffff;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block;
    float: none;
    color: #ffffff;
  }
}

.coIcon {
  width: 50px;
  height: 50px;
  transition: transform .5s;
  align-items: center;
  padding: 0;
}

.coIcon:hover {
  transform: scale(4);
  border-radius: 5px;
}

.vertical-timeline-element-content {
  border-radius: 5px;
  padding: 1em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.vertical-timeline-element-title {
  font-size: 1.1em;
  color: #3263ac;
}

.vertical-timeline-element-subtitle {
  font-size: .9em;
  color: #243f68;
}

#description {
  margin: 0;
  font-size: 1em;
  color: black;
}

.workButton {
  background-color: #06d6a0;
}

.schoolButton {
  background-color: #f9c74f;
}

#particles-js {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgb(17, 24, 39);
}