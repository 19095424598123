.full-screen {
    position: fixed;
    height: 800px;
    width: 1300px;
    background-color: hsla(221, 38%, 21%, 0.795);
    color: #f9fafb;
    border-radius: 1rem;
}

.flex-row-scroll {
    display: flex;
    padding: 3rem;
    gap: 0.75rem;
    width: 100%;
    height: 100%;
}


.column {
    width: 224px;
    flex-shrink: 0;
}

.column-header {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.column-body {
    height: 100%;
    width: 100%;
    transition: background-color 0.2s;
}


.column-body-active {
    background-color: rgba(31, 41, 55, 0.5);
}

.column-body-inactive {
    background-color: transparent;
}


.text-primary {
    font-size: 1rem;
    font-weight: 500;
}

.text-counter {
    font-size: 0.875rem;
    border-radius: 0.375rem;
    color: #9ca3af;
}


.card {
    cursor: grab;
    border: 1px solid #374151;
    background-color: #1f2937;
    padding: 8px;
    border-radius: 0.375rem;
    margin-top: 2px;
    margin-bottom: 2px;
}

.card-active {
    cursor: grabbing;
}

.card-title {
    font-size: 0.875rem;
    color: #f9fafb;
}


.drop-indicator {
    height: 2px;
    width: 100%;
    background-color: #c084fc;
    opacity: 0;
}


.burn-barrel {
    margin-top: 40px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    height: 224px;
    width: 224px;
    flex-shrink: 0;
    place-content: center;
    border-radius: 0.375rem;
    font-size: 3rem;
}

.burn-barrel-active {
    border-color: #991b1b;
    background-color: rgba(153, 27, 27, 0.2);
    color: #ef4444;
}

.burn-barrel-inactive {
    border-color: #6b7280;
    background-color: rgba(107, 114, 128, 0.2);
    color: #6b7280;
}


.interactive:hover {
    background-color: #d1d5db;
}

.interactive-text:hover {
    color: #f9fafb;
}

.col0 {
    flex-shrink: 0;
    width: 14rem;
}

.col1 {
    display: flex;
    margin-bottom: 0.75rem;
    justify-content: space-between;
    align-items: center;
}

.colActive {
    height: 600px;
    width: 100%;
    transition-property: color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    background-color: rgba(31, 41, 55, 0.5);
}

.colInactive {
    height: 100vh;
    width: 100%;
    transition-property: color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    background-color: transparent;
}

.text-gray,
.text-yellow,
.text-green,
.text-blue {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-weight: 500;
    width: 100%;
}

.text-gray {
    color: rgb(115 115 115);
}

.text-yellow {
    color: rgb(254 240 138);
}

.text-blue {
    color: rgb(191 219 254);
}

.text-green {
    color: rgb(167 243 208);
}

.kheader {
    font-family: 'Roboto', sans-serif;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #9ca3af;
}

.form-button {
    display: flex;
    margin-top: 0.375rem;
    gap: 0.375rem;
    justify-content: flex-end;
    align-items: center;
}

.form-button:hover {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 0.75rem;
    line-height: 1rem;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    color: rgb(250 250 250);
}

.animate-bounce {
    animation: bounce 1s infinite;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(-25%);
        animationTimingFunction: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        transform: translateY(0);
        animationTimingFunction: cubic-bezier(0, 0, 0.2, 1);
    }
}

.barrel-item {
    display: grid;
    place-content: center;
}





.card-input {
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid #c084fc;
    background-color: rgba(192, 132, 252, 0.2);
    padding: 12px;
    font-size: 0.875rem;
    color: #f9fafb;
    outline: none;
}

.card-input::placeholder {
    color: #c084fc;
}


.form-buttons {
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
}


.button {
    padding: 6px 12px;
    font-size: 0.75rem;
    transition: color 0.2s;
}


.button-close {
    color: #9ca3af;
}

.button-close:hover {
    color: #f9fafb;
}


.button-submit {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 0.375rem;
    background-color: #f9fafb;
    color: #1f2937;
}

.button-submit:hover {
    background-color: #d1d5db;
}


.button-add-card {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    font-size: 0.75rem;
    color: #9ca3af;
    background-color: #111827;
    border-width: 0px;
}

.button-add-card:hover {
    color: #f9fafb;
}